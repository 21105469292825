import { render, staticRenderFns } from "./RkButton.vue?vue&type=template&id=63ac8d8a&scoped=true"
import script from "./RkButton.vue?vue&type=script&lang=js"
export * from "./RkButton.vue?vue&type=script&lang=js"
import style0 from "./RkButton.vue?vue&type=style&index=0&id=63ac8d8a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63ac8d8a",
  null
  
)

export default component.exports