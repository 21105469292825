//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
import { focus } from '~/rockitUI/components/utilities/directives';
export default defineComponent({
  name: 'RkButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    display: {
      type: [String, Array],
      default: null,
    },
    link: {
      type: [String, Object],
      default: '',
    },
    loadingState: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    focus,
  },
  setup() {
    const buttonClass = (display) => {
      if (display)
        if (Array.isArray(display)) {
          const typePrefixed = display.map((el) => 'rk-button--' + el);
          return typePrefixed.join(' ');
        } else {
          return `rk-button--${display}`;
        }
      else return '';
    };
    const linkActive = (link, disabled) => {
      return link && disabled;
    };
    const buttonActive = (link, disabled) => {
      return !link && disabled;
    };
    return {
      buttonClass,
      linkActive,
      buttonActive,
    };
  },
});
