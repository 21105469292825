var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.link ? 'RkLink' : 'button',_vm._g(_vm._b({directives:[{name:"focus",rawName:"v-focus"}],tag:"component",class:[
    'rk-button transition-colors',
    {
      'is-disabled--button': _vm.buttonActive(_vm.link, _vm.disabled),
      'is-disabled--link': _vm.linkActive(_vm.link, _vm.disabled),
      'is-loading--button': _vm.loadingState,
      'has-additional': _vm.$slots.additional,
    },
    _vm.buttonClass(_vm.display) ],attrs:{"aria-disabled":_vm.disabled,"link":_vm.link,"disabled":_vm.disabled,"type":_vm.link ? '' : _vm.type}},'component',_vm.$attrs,false),!_vm.disabled ? _vm.$listeners : {}),[_c('span',{staticClass:"rk-button__main"},[(_vm.$slots.leftIcon)?_c('span',{staticClass:"rk-button__left-icon rk-button__icon"},[_vm._t("leftIcon")],2):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.$slots.rightIcon)?_c('span',{staticClass:"rk-button__right-icon rk-button__icon"},[_vm._t("rightIcon")],2):_vm._e(),_vm._v(" "),(_vm.loadingState)?_c('span',{staticClass:"rk-button__loading"},[_vm._t("loader",function(){return [_c('div',{staticClass:"rk-loader__loading animate-spin rounded-full border-2 border-solid",staticStyle:{"border-top-color":"transparent"}})]})],2):_vm._e()],2),_vm._v(" "),(_vm.$slots.additional)?_c('span',{staticClass:"rk-button__additional"},[_vm._t("additional")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }